import ApiService from "@/core/services/api/v2/api.service";

const VEHICLE_MODEL_FILTER_KEY = "VehicleModelFilter";
const VEHICLE_MODEL_BRAND_LIST_KEY = "VehicleModelBrandList";
const VEHICLE_MODEL_MODEL_LIST_KEY = "VehicleModelModelList";
const VEHICLE_MODEL_VERSION_LIST_KEY = "VehicleModelVersionList";

const VehicleModelService = {
  getProfile(vehicleModelID) {
    return new Promise(resolve => {
      ApiService.get("vehicleModel/" + vehicleModelID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(vehicleModelID, newData) {
    return new Promise(resolve => {
      ApiService.put("vehicleModel/" + vehicleModelID, newData)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(vehicleModelID) {
    return new Promise(resolve => {
      ApiService.delete("vehicleModel/" + vehicleModelID)
        .then(response => {
          this.cleanCachedList();
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.post("vehicleModel", newData)
        .then(response => {
          this.cleanCachedList();
          // Response (if correct) holds the new VehicleModelID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(
    searchText,
    filterByBrand,
    filterByModel,
    filterByVersion,
    filterByVehicleTypeID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByBrand = filterByBrand ? filterByBrand.trim() : "";
      if (filterByBrand.length > 0) {
        queryParams.Brand = filterByBrand;
      }

      filterByModel = filterByModel ? filterByModel.trim() : "";
      if (filterByModel.length > 0) {
        queryParams.Model = filterByModel;
      }

      filterByVersion = filterByVersion ? filterByVersion.trim() : "";
      if (filterByVersion.length > 0) {
        queryParams.Version = filterByVersion;
      }

      filterByVehicleTypeID = parseInt(filterByVehicleTypeID);
      if (!isNaN(filterByVehicleTypeID) && filterByVehicleTypeID > 0) {
        queryParams.VehicleTypeID = filterByVehicleTypeID;
      }

      window.sessionStorage.setItem(
        VEHICLE_MODEL_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("vehicleModel", { params: queryParams })
        .then(response => {
          // Return full response, not just "VehicleModels" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(VEHICLE_MODEL_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  listAllBrands() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(
        VEHICLE_MODEL_BRAND_LIST_KEY
      );

      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("vehicleModelBrand")
          .then(response => {
            let newList = response.data.response.Brands;

            window.sessionStorage.setItem(
              VEHICLE_MODEL_BRAND_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  listAllModelsByBrand(whichBrand) {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList1 = window.sessionStorage.getItem(
        VEHICLE_MODEL_MODEL_LIST_KEY
      );
      let cachedList = cachedList1 ? JSON.parse(cachedList1) : {};

      if (cachedList[whichBrand]) {
        resolve(cachedList[whichBrand]);
      } else {
        const queryParams = { Brand: whichBrand };
        ApiService.query("vehicleModelModel", { params: queryParams })
          .then(response => {
            let newSublist = response.data.response.Models;

            cachedList[whichBrand] = newSublist;
            window.sessionStorage.setItem(
              VEHICLE_MODEL_MODEL_LIST_KEY,
              JSON.stringify(cachedList)
            );

            resolve(newSublist);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  listAllVersionsByBrandAndModel(whichBrand, whichModel) {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList1 = window.sessionStorage.getItem(
        VEHICLE_MODEL_VERSION_LIST_KEY
      );
      let cachedList = cachedList1 ? JSON.parse(cachedList1) : {};

      if (cachedList[whichBrand] && cachedList[whichBrand][whichModel]) {
        resolve(cachedList[whichBrand][whichModel]);
      } else {
        const queryParams = { Brand: whichBrand, Model: whichModel };
        ApiService.query("vehicleModelVersion", { params: queryParams })
          .then(response => {
            let newSublist = response.data.response.Versions;

            if (!cachedList[whichBrand]) {
              cachedList[whichBrand] = {};
            }
            cachedList[whichBrand][whichModel] = newSublist;
            window.sessionStorage.setItem(
              VEHICLE_MODEL_VERSION_LIST_KEY,
              JSON.stringify(cachedList)
            );

            resolve(newSublist);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  cleanCachedList() {
    window.sessionStorage.removeItem(VEHICLE_MODEL_BRAND_LIST_KEY);
    window.sessionStorage.removeItem(VEHICLE_MODEL_MODEL_LIST_KEY);
    window.sessionStorage.removeItem(VEHICLE_MODEL_VERSION_LIST_KEY);
  }
};

export default VehicleModelService;
